<template>
  <prefund-header-wrapper>
    <a-card :bordered="false">
      <a-tabs v-model="activeKey" @tabClick="changeTab">
        <a-tab-pane :key="1" tab="可视化"/>
<!--
        <a-tab-pane :key="2" tab="常规报表"/>
-->
      </a-tabs>
      <a-card :bordered="false" v-show="activeKey===1">
        <a-row>
          <a-col :span="8">
            <div style="padding: 2px">
              <div class="one" style="float: left;" >
                <span :class="isCancelSelect === '1'?'isActive': ''" @click="handelCancelClick('1')" style="font-size: 12px">昨日</span>
                <span :class="isCancelSelect === '7'?'isActive': ''" @click="handelCancelClick('7')" style="font-size: 12px">近7日</span>
                <span :class="isCancelSelect === '30'?'isActive': ''" @click="handelCancelClick('30')" style="margin-right:10px; font-size: 12px">近30日</span>
              </div>
              <a-range-picker style="width: 300px" valueFormat="YYYY-MM-DD"  @change="cancelDateChange"  size="small"/>
            </div>
            <div id="cancelChart" style="width: 600px;height: 400px"></div>
          </a-col>
          <a-col :span="8">
            <div style="padding: 2px">
              <div class="one"  style="float: left;">
                <span :class="isRefundSelect === '1'?'isActive': ''" @click="handelRefundClick('1')" style="font-size: 12px">昨日</span>
                <span :class="isRefundSelect === '7'?'isActive': ''" @click="handelRefundClick('7')" style="font-size: 12px">近7日</span>
                <span :class="isRefundSelect === '30'?'isActive': ''" @click="handelRefundClick('30')" style="font-size: 12px;margin-right:10px;">近30日</span>
              </div>
              <a-range-picker  style="width: 300px" valueFormat="YYYY-MM-DD"  @change="refundDateChange"  size="small"/>
            </div>
            <div id="refundChart" style="width: 600px;height: 400px"></div>
          </a-col>
          <a-col :span="8">
            <div style="padding: 2px">
              <div class="one" style="float: left;" >
                <span :class="isTimeSelect === '1'?'isActive': ''" @click="handelTimeClick('1')" style="font-size: 12px">昨日</span>
                <span :class="isTimeSelect === '7'?'isActive': ''" @click="handelTimeClick('7')" style="font-size: 12px">近7日</span>
                <span :class="isTimeSelect === '30'?'isActive': ''" @click="handelTimeClick('30')" style="margin-right:10px; font-size: 12px">近30日</span>
              </div>
              <a-range-picker style="width: 300px" valueFormat="YYYY-MM-DD"  @change="timeDateChange"  size="small"/>
            </div>
            <div id="timeChart" style="width: 600px;height: 400px"></div>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="18">
            <div style="padding: 2px">
              <div class="one"  style="float: left;">
                <span :class="isNationSelect === '1'?'isActive': ''" @click="handelNationClick('1')" style="font-size: 12px">昨日</span>
                <span :class="isNationSelect === '7'?'isActive': ''" @click="handelNationClick('7')" style="font-size: 12px">近7日</span>
                <span :class="isNationSelect === '30'?'isActive': ''" @click="handelNationClick('30')" style="margin-right:10px;font-size: 12px">近30日</span>
              </div>
              <a-range-picker  valueFormat="YYYY-MM-DD"  @change="nationDateChange"  size="small"/>
            </div>
            <div id="nationChart" style="width: 600px;height: 400px"></div>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <div style="padding: 5px">
              <div class="two" style="float: right;">
                <span :class="isOrderSelect === 'days'?'isActive': ''" @click="handelOrderClick('days')">按天</span>
                <span :class="isOrderSelect === 'months'?'isActive': ''" @click="handelOrderClick('months')">按月</span>
                <span :class="isOrderSelect === 'years'?'isActive': ''" @click="handelOrderClick('years')" style="margin-right:10px;">按年</span>
              </div>
              <a-range-picker valueFormat="YYYY-MM-DD"  @change="orderAmountDateChange"  size="large"/>
            </div>
            <div id="orderAmountChart" style="height: 400px"></div>
          </a-col>

          <a-col :span="12">
            <div style="padding: 5px">
              <div class="two" style="float: right;">
                <span :class="isOrderCountSelect === 'days'?'isActive': ''" @click="handelOrderCountClick('days')">按天</span>
                <span :class="isOrderCountSelect === 'months'?'isActive': ''" @click="handelOrderCountClick('months')">按月</span>
                <span :class="isOrderCountSelect === 'years'?'isActive': ''" @click="handelOrderCountClick('years')" style="margin-right:10px;">按年</span>
              </div>
              <a-range-picker  valueFormat="YYYY-MM-DD"  @change="orderCountDateChange"  size="large"/>
            </div>
            <div id="orderCountChart" style="height: 400px"></div>
          </a-col>
        </a-row>
      </a-card>
    </a-card>
  </prefund-header-wrapper>
</template>

<script>
import {tableMixin} from '@/store/table-mixin'
import * as echarts from 'echarts'
import {
  orderAmountChart,
  orderCancelChart,
  orderNationChart,
  orderRefundChart,
  orderTimeChart
} from "@/api/statistics/order";
import moment from 'moment';

export default {
  name: 'OrderSta',
  components: {},
  mixins: [tableMixin],
  data() {
    return {
      activeKey: 1,
      starList: [],
      clubList: [],
      cancelParam: {
        chartType: '1',
        startTime: undefined,
        endTime:undefined,
      },
      refundParam: {
        chartType: '1',
        startTime: undefined,
        endTime:undefined,
      },
      cancelOrderParam: {
        chartType: '1',
        startTime: undefined,
        endTime:undefined,
      },
      refundOrderParam: {
        chartType: '1',
        startTime: undefined,
        endTime:undefined,
      },
      timeParam: {
        chartType: '1',
        startTime: undefined,
        endTime:undefined,
      },
      nationParam: {
        chartType: '1',
        startTime: undefined,
        endTime:undefined,
      },
      activeParam: {
        chartType: '1',
        endTime:undefined,
      },
      orderAmountParam: {
        chartType: 'days',
        dateRange: [],
        startTime: undefined,
        endTime:undefined,

      },
      orderCountParam: {
        chartType: 'days',
        dateRange: [],
        startTime: undefined,
        endTime:undefined,
      },
      /*销售数据选择*/
      isOrderSelect: 'days',
      isOrderCountSelect: 'days',
      /*销售数据选择*/
      isCancelSelect: undefined,
      isRefundSelect: undefined,
      isTimeSelect: undefined,
      isNationSelect: undefined,
      isCancelOrderSelect: undefined,
      isOrderAmountSelect: undefined,
      isRefundOrderSelect: undefined,
      isActiveSelect: undefined,

    }
  },
  filters: {},
  created() {
  },
  mounted() {
    this.drawRefundChart()
    this.drawCancelChart()
    this.drawTimeChart()
    this.drawNationChart()
    this.drawOrderAmountChart()
    this.drawOrderCountChart()
  },
  computed: {},
  watch: {},
  methods: {
    moment,
    changeTab(index) {
      if (index == null) {
        index = undefined;
      }
      this.activeKey = index;
    },

    subMonth(num) {
      const newDate = new Date();
      newDate.setMonth(newDate.getMonth() - num);
      return newDate;
    },
    subDay(num) {
      return moment().add(-num, 'days');
    },

    cancelDateChange(val) {
      this.cancelParam.startTime = val[0] + ' 00:00:00';
      this.cancelParam.endTime = val[1] + ' 23:59:59';
      this.drawCancelChart();
    },
    refundDateChange(val) {
      this.refundParam.startTime = val[0] + ' 00:00:00';
      this.refundParam.endTime = val[1]+ ' 23:59:59';
      this.drawRefundChart();
    },
    handelCancelClick(val) {
      this.isCancelSelect = val;
      this.cancelParam.chartType = '1';
      this.cancelParam.startTime = this.subDay(val).format('YYYY-MM-DD') + " 00:00:00";
      this.cancelParam.endTime = moment().format('YYYY-MM-DD') + " 23:59:59";
      this.drawCancelChart();
    },
    handelRefundClick(val) {
      this.isRefundSelect = val;
      this.refundParam.chartType = '1';
      this.refundParam.startTime = this.subDay(val).format('YYYY-MM-DD') + " 00:00:00";
      this.refundParam.endTime = moment().format('YYYY-MM-DD') + " 23:59:59";
      this.drawRefundChart();
    },

    timeDateChange(val) {
      this.timeParam.startTime = val[0] + ' 00:00:00';
      this.timeParam.endTime = val[1] + ' 23:59:59';
      this.drawTimeChart();
    },
    nationDateChange(val) {
      this.nationParam.startTime = val[0] + ' 00:00:00';
      this.nationParam.endTime = val[1] + ' 23:59:59';
      this.drawNationChart();
    },

    handelTimeClick(val) {
      this.isTimeSelect = val;
      this.timeParam.chartType = '1';
      this.timeParam.startTime = this.subDay(val).format('YYYY-MM-DD') + " 00:00:00";
      this.timeParam.endTime = moment().format('YYYY-MM-DD') + " 23:59:59";
      this.drawTimeChart();
    },


    handelNationClick(val) {
      this.isNationSelect = val;
      this.nationParam.chartType = '1';
      this.nationParam.startTime = this.subDay(val).format('YYYY-MM-DD') + " 00:00:00";
      this.nationParam.endTime = moment().format('YYYY-MM-DD') + " 23:59:59";
      this.drawNationChart();
    },


    orderAmountDateChange(val) {
      this.orderAmountParam.startTime = val[0] + ' 00:00:00';
      this.orderAmountParam.endTime = val[1] + ' 23:59:59';
      this.drawOrderAmountChart()
    },
    orderCountDateChange(val) {
      this.orderCountParam.startTime = val[0] + ' 00:00:00';
      this.orderCountParam.endTime = val[1]+ ' 23:59:59';
      this.drawOrderCountChart()
    },
    handelOrderClick(val) {
      this.isOrderSelect = val;
      this.orderAmountParam.chartType = val;
      this.drawOrderAmountChart();
    },
    handelOrderCountClick(val) {
      this.isOrderCountSelect = val;
      this.orderCountParam.chartType = val;
      this.drawOrderCountChart();
    },


    drawCancelChart() {
      // // 添加
      var chartDom = document.getElementById("cancelChart");
      var myChart = echarts.init(chartDom);
      var option;
      orderCancelChart(this.cancelParam).then((res) => {
        if (res.code === "20001") {
          option = {
            title: {
              text: '订单取消原因分布',
              //subtext: 'Fake Data',
              left: 'center'
            },
            tooltip: {
              trigger: 'item'
            },
            legend: {
              orient: 'vertical',
              left: 'left'
            },
            series: [
              {
                name: '原因',
                type: 'pie',
                radius: '50%',
                data: res.data,
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                  }
                }
              }
            ]
          };
          option && myChart.setOption(option);
        }
      });
    },
    drawRefundChart() {
      // // 添加
      var chartDom = document.getElementById("refundChart");
      var myChart = echarts.init(chartDom);
      var option;
      orderRefundChart(this.refundParam).then((res) => {
        if (res.code === "20001") {
          option = {
            title: {
              text: '订单退款原因分布',
              //subtext: 'Fake Data',
              left: 'center'
            },
            tooltip: {
              trigger: 'item'
            },
            legend: {
              orient: 'vertical',
              left: 'left'
            },
            series: [
              {
                name: '退款原因',
                type: 'pie',
                radius: '50%',
                data: res.data,
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                  }
                }
              }
            ]
          };
          option && myChart.setOption(option);
        }
      });
    },

    drawTimeChart() {
      // // 添加
      var chartDom = document.getElementById("timeChart");
      var myChart = echarts.init(chartDom);
      var option;
      orderTimeChart(this.cancelParam).then((res) => {
        if (res.code === "20001") {
          option = {
            title: {
              text: '订单下单时段分布',
              //subtext: 'Fake Data',
              left: 'center'
            },
            tooltip: {
              trigger: 'item'
            },
            legend: {
              orient: 'vertical',
              left: 'left'
            },
            series: [
              {
                name: '时段',
                type: 'pie',
                radius: '50%',
                data: res.data,
                color: ['#FAC898','#ff6200','#009dff','#4000FF','#00FFD6'], //
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                  }
                }
              }
            ]
          };
          option && myChart.setOption(option);
        }
      });
    },

    drawNationChart() {
      // // 添加
      var chartDom = document.getElementById("nationChart");
      var myChart = echarts.init(chartDom);
      var option;
      orderNationChart(this.nationParam).then((res) => {
        if (res.code === "20001") {
          option = {
            title: {
              text: '订单收货地址区域',
              //subtext: 'Fake Data',
              left: 'center'
            },
            tooltip: {
              trigger: 'item'
            },
            legend: {
              orient: 'vertical',
              left: 'left'
            },
            series: [
              {
                name: '区域',
                type: 'pie',
                radius: '50%',
                data: res.data,
                color: ['#0a53e5','#ff0000'], //
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                  }
                }
              }
            ]
          };
          option && myChart.setOption(option);
        }
      });
    },
    drawOrderAmountChart() {
      // // 添加
      var chartDom = document.getElementById("orderAmountChart");
      var myChart = echarts.init(chartDom);
      var option;
      orderAmountChart(this.orderAmountParam).then((res) => {
        if (res.code === "20001") {
          let yaxis = res.data.yyAxis;
          let xAxis = res.data.xxAxis;
          option = {
            title: {
              text: '订单销售金额趋势图'
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'cross',
                label: {
                  backgroundColor: '#6a7985'
                }
              }
            },
            legend: {
              data: ['订单销售额', '运费金额','退款金额']
            },
            toolbox: {
              feature: {
                saveAsImage: {}
              }
            },
            grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
            },
            xAxis: [
              {
                type: 'category',
                boundaryGap: false,
                data: xAxis
              }
            ],
            yAxis: [
              {
                type: 'value'
              }
            ],
            series: [
              {
                name: '运费金额',
                type: 'line',
                stack: 'shippingAmount',
                label: {
                  normal: {
                    show: true,
                    formatter: function(params) {
                      // 当数值为0时不显示标签
                      if (params.value === 0) {
                        return '';
                      } else if (params.value === '0.00') {
                        return '';
                      }
                      return params.value;
                    }
                  }
                },
                areaStyle: {
                  color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                      offset: 0, color: 'rgba(211,89,13,0.5)'    // 0% 处的颜色
                    }, {
                      offset: 1, color: 'rgba(197,105,67,0)' //   100% 处的颜色
                    }],
                    global: false // 缺省为 false
                  }
                },
                lineStyle: {
                  color: '#ee8e22' // 这里设置
                },
                itemStyle: { // 设置标点的默认样式
                  normal: {
                    color: '#ee8e22' // 设置标点的颜色为蓝色
                  }
                },
                emphasis: {
                  focus: 'series'
                },
                data: yaxis.shippingPayAmountList
              },
              {
                name: '退款金额',
                type: 'line',
                stack: 'refundAmount',
                lineStyle: {
                  color: '#f30202' // 这里设置
                },
                itemStyle: { // 设置标点的默认样式
                  normal: {
                    color: '#f30202' // 设置标点的颜色为蓝色
                  }
                },
                label: {
                  normal: {
                    show: true,
                    formatter: function(params) {
                      // 当数值为0时不显示标签
                      if (params.value === 0) {
                        return '';
                      } else if (params.value === '0.00') {
                        return '';
                      }
                      return params.value;
                    }
                  }
                },
                areaStyle: {
                  color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                      offset: 0, color: 'rgba(218,119,119,0.5)'    // 0% 处的颜色
                    }, {
                      offset: 1, color: 'rgba(239,5,5,0)' //   100% 处的颜色
                    }],
                    global: false // 缺省为 false
                  }
                },
                emphasis: {
                  focus: 'series'
                },
                data: yaxis.refundAmountList
              },
              {
                name: '订单销售额',
                type: 'line',
                stack: 'payAmount',
                lineStyle: {
                  color: '#46f302' // 这里设置
                },
                itemStyle: { // 设置标点的默认样式
                  normal: {
                    color: '#46f302' // 设置标点的颜色为蓝色
                  }
                },
                areaStyle: {
                  color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                      offset: 0, color: 'rgba(45,255,0,0.5)'    // 0% 处的颜色
                    }, {
                      offset: 1, color: 'rgba(123,203,96,0)' //   100% 处的颜色
                    }],
                    global: false // 缺省为 false
                  }
                },
                label: {
                  normal: {
                    show: true,
                    formatter: function(params) {
                      // 当数值为0时不显示标签
                      if (params.value === 0) {
                        return '';
                      } else if (params.value === '0.00') {
                        return '';
                      }
                      return params.value;
                    }
                  },
                },
                emphasis: {
                  focus: 'series'
                },
                data: yaxis.payAmountList
              },
            ]
          };


          option && myChart.setOption(option);
        }
      });
    },

    drawOrderCountChart() {
      // // 添加
      var chartDom = document.getElementById("orderCountChart");
      var myChart = echarts.init(chartDom);
      var option;
      orderAmountChart(this.orderCountParam).then((res) => {
        if (res.code === "20001") {
          let yaxis = res.data.yyAxis;
          let xAxis = res.data.xxAxis;
          option = {
            title: {
              text: '订单量趋势图'
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'cross',
                label: {
                  backgroundColor: '#6a7985'
                }
              }
            },
            legend: {
              data: ['订单量', '退款量']
            },
            toolbox: {
              feature: {
                saveAsImage: {}
              }
            },
            grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
            },
            xAxis: [
              {
                type: 'category',
                boundaryGap: false,
                data: xAxis
              }
            ],
            yAxis: [
              {
                type: 'value'
              }
            ],
            series: [
              {
                name: '退款量',
                type: 'line',
                lineStyle: {
                  color: '#f30202' // 这里设置
                },
                itemStyle: { // 设置标点的默认样式
                  normal: {
                    color: '#f30202' // 设置标点的颜色为蓝色
                  }
                },
                label: {
                  normal: {
                    show: true,
                    formatter: function(params) {
                      // 当数值为0时不显示标签
                      if (params.value === 0) {
                        return '';
                      } else if (params.value === '0.00') {
                        return '';
                      } else {
                        return params.value;
                      }
                    }
                  }
                },
                stack: 'refundCount',
                areaStyle: {
                  color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                      offset: 0, color: 'rgba(238,177,184,0.5)'    // 0% 处的颜色
                    }, {
                      offset: 1, color: 'rgba(255,0,0,0)' //   100% 处的颜色
                    }],
                    global: false // 缺省为 false
                  }
                },
                emphasis: {
                  focus: 'series'
                },
                data: yaxis.refundCountList
              },
              {
                name: '订单量',
                type: 'line',
                stack: 'orderCount',
                areaStyle: {
                  color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                      offset: 0, color: 'rgba(45,255,0,0.5)'    // 0% 处的颜色
                    }, {
                      offset: 1, color: 'rgba(123,203,96,0)' //   100% 处的颜色
                    }],
                    global: false // 缺省为 false
                  }
                },
                lineStyle: {
                  color: '#46f302' // 这里设置
                },
                itemStyle: { // 设置标点的默认样式
                  normal: {
                    color: '#46f302' // 设置标点的颜色为蓝色
                  }
                },
                label: {
                  show: true,
                  position: 'top'
                },
                emphasis: {
                  focus: 'series'
                },
                data: yaxis.orderCountList
              },
            ]
          };


          option && myChart.setOption(option);
        }
      });
    },

  }
}
</script>
<style lang="less" scoped>
.one {
  margin-left: 1px;
  margin-right: 1px;

  span {
    display: inline-block;
    line-height: 23px;
    width: 60px;
    text-align: center;
    border-radius: 6px;
    border: 1px solid #999999;
    // color: #1890ff;
  }

  span:nth-child(1) {
    border-radius: 6px 0 0 6px;
    border-right: none;

  }

  span:nth-child(2) {
    border-radius: 0;
    border-right: none;

  }

  span:nth-child(3) {
    border-radius: 0;
    border-right: none;

  }

  span:nth-child(4) {
    border-radius: 0 6px 6px 0;
    position: relative;
  }

  span:nth-child(4) .el-date-editor {
    position: absolute; //绝对定位
    top: 0;
    left: 0;
    opacity: 0; //设置完全透明
  }
}

.isActive {
  background: #1890ff;
  color: #ffffff !important;
  border: 1px solid #1890ff !important;
}

.ant-card-body {
  padding: 5px;
}

.two {
  margin-left: 10px;
  margin-right: 125px;

  span {
    display: inline-block;
    line-height: 40px;
    width: 60px;
    text-align: center;
    border-radius: 6px;
    border: 1px solid #999999;
    // color: #1890ff;
  }

  span:nth-child(1) {
    border-radius: 6px 0 0 6px;
    border-right: none;

  }

  span:nth-child(2) {
    border-radius: 0;
    border-right: none;

  }

  span:nth-child(3) {
    border-radius: 0;
    border-right: none;

  }

  span:nth-child(4) {
    border-radius: 0 6px 6px 0;
    position: relative;
  }

  span:nth-child(4) .el-date-editor {
    position: absolute; //绝对定位
    top: 0;
    left: 0;
    opacity: 0; //设置完全透明
  }
}

</style>
